<template>
  <!-- employee -->
  <v-autocomplete
    v-model="employee"
    :items="employees"
    outlined
    dense
    clearable
    auto-select-first
    :search-input.sync="search"
    label="Employee"
    item-text="name"
    item-value="id"
    :loading="employeeLoading"
    :append-icon="icon.search"
    :class="formErrorFields.employeeErrorMessage.length > 0 ? 'mb-3' : ''"
    :error-messages="formErrorFields.employeeErrorMessage"
    :disabled="disabled"
    @change="selected"
    @keypress.enter="searchEmployee=search"
  >
    <template slot="selection" slot-scope="data">
      {{ itemText !== undefined ? data.item[itemText] : data.item.name }}
    </template>
  </v-autocomplete>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiSearchWeb } from '@mdi/js'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'

export default {
  props: {
    employeeId: Number,
    employeeNumber: String,
    disabled: Boolean,
    errorMessage: [],
    itemText: String,
  },

  setup(props, { emit }) {
    const icon = { search: mdiSearchWeb }
    const search = ref('')
    const searchEmployee = ref('')
    const employeeLoading = ref(false)
    const employee = ref('')
    const employees = ref([])
    const formErrorFields = ref({
      employeeErrorMessage: '',
    })

    watch(() => props.employeeNumber, val => {
      get(`${process.env.VUE_APP_URI}/api/payroll/employees/search/${val}`, employees, employeeLoading)
      employee.value = props.employee_id
    })

    watch(() => props.errorMessage, val => {
      formErrorFields.value.employeeErrorMessage = val
    })

    watch(() => props.employeeId, val => {
      employee.value = val
    })

    watch(searchEmployee, val => {
      if (val.length >= 3) {
        get(`${process.env.VUE_APP_URI}/api/payroll/employees/search/${val}`, employees, employeeLoading)
      } else {
        alert('Keyword must be 3 character minimum.')
      }
    })

    const selected = val => {
      formError().remove('employee', formErrorFields.value)
      emit('outputId', val)
    }

    return {
      icon,
      search,
      searchEmployee,
      employee,
      employees,
      employeeLoading,
      formErrorFields,
      formError,
      selected,
    }
  },
}
</script>
